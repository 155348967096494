import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Layout from "../../../components/layout";
import ProductTable from "../../../components/productTable";
import SEO from "../../../components/seo";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import ProductImage from "../../../images/product/interactive-smart-board/mx-series/Product-Summary-Interactive-Smart-Board.jpg";
import Content from "../../../content/produk/interactive-smart-board/mx-series/Product-page.json";
import Datasheet from "../../../content/produk/interactive-smart-board/mx-series/mx-series-brochure.pdf";

const smartBoard = ({ siteTitle }) => {
    const headerStyle = {
        textAlign: "center",
        fontSize: "32px",
        fontWeight: "bold",
        marginBottom: "20px",
        paddingBottom: "20px",
        position: "relative",
        color: "#2d405f",
    };
    return (
        <Layout>
            <SEO
                title={Content.seo.title}
                description={Content.seo.description}
                lang={Content.seo.lang}
            />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1 style={headerStyle}>SMART Board® MX Series</h1>
                        <Row>
                            <Col sm={true}>
                                <Image src={ProductImage} fluid />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2>Product Summary</h2>
                                </div>
                                <p style={{ textAlign: "justify" }}>
                                    SMART Board® adalah alat belajar mengajar
                                    interaktif yang dapat menjadi alternatif
                                    papan tulis dan proyektor konvensional di
                                    ruang kelas. SMART Board® tersedia dalam
                                    ukuran 65&quot;, 75&quot; dan 86&quot;.
                                </p>
                                <p>
                                    <Button
                                        as={"a"}
                                        href={`https://wa.me/6289506017899?text=${encodeURIComponent(
                                            "Halo, saya tertarik ingin membeli interactive smart board MX"
                                        )}`}
                                        aria-label="WhatsApp"
                                        target={"_blank"}
                                        rel={"noreferrer noopener"}
                                        style={{
                                            backgroundColor: "#25D366",
                                            borderColor: "#25D366",
                                        }}
                                        size="md"
                                        block
                                    >
                                        <i className="fab fa-whatsapp fa-fw" />
                                        Pesan Melalui WhatsApp
                                    </Button>
                                    <Button
                                        as={"a"}
                                        href={`mailto:info@ilife.id?subject=${"[BELI] Interactive smart board MX"}&body=${"Halo, saya tertarik untuk membeli interactive smart board MX"}`}
                                        aria-label="Email"
                                        target={"_blank"}
                                        rel={"noreferrer noopener"}
                                        variant="primary"
                                        size="md"
                                        block
                                    >
                                        <i className="far fa-envelope fa-fw" />
                                        Pesan Melalui Email
                                    </Button>
                                    <Button
                                        as={"a"}
                                        href={Datasheet}
                                        aria-label="WhatsApp"
                                        target={"_blank"}
                                        rel={"noreferrer noopener"}
                                        style={{
                                            backgroundColor: "#2383C4",
                                            borderColor: "#2383C4",
                                        }}
                                        size="md"
                                        block
                                    >
                                        <i className="far fa-file-pdf fa-fw"></i>
                                        Datasheet
                                    </Button>
                                    {/*<OrderButton*/}
                                    {/*    messageText={Content.messageText}*/}
                                    {/*/>*/}
                                </p>
                            </Col>
                            <Col>
                                <h3>Write effortlessly</h3>
                                <p style={{ textAlign: "justify" }}>
                                    HyPrTouch™ dengan Advanced IR memberikan
                                    lebih dari dua kali keakuratan daripada
                                    teknologi inframerah tradisional untuk
                                    pengalaman menulis yang lebih intuitif dan
                                    alami.
                                </p>
                                <h3>iQ embedded computing</h3>
                                <p style={{ textAlign: "justify" }}>
                                    Beralih dengan satu ketukan dari
                                    menyampaikan pelajaran, menulis di papan
                                    tulis, hingga menjelajahi internet - membuat
                                    siswa aktif dalam kegiatan belajar mengajar.
                                </p>
                                <h3>Commercial grade</h3>
                                <p style={{ textAlign: "justify" }}>
                                    Seri MX memiliki rating 50.000 jam
                                    penggunaan yang andal dan SMART Accelerated
                                    Life Testing memastikan waktu henti{" "}
                                    <i>(downtime)</i> yang lebih sedikit dan
                                    masa pakai yang lebih lama.
                                </p>
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2>Built to use. Built to last.</h2>
                                </div>
                                <p style={{ textAlign: "justify" }}>
                                    Tahan lama dan commercial-grade, layar SMART
                                    Board telah menerima banyak sertifikasi
                                    keselamatan, peraturan, dan lingkungan,
                                    memberi anda ketenangan bahwa layar tersebut
                                    mematuhi persyaratan pemerintah atau
                                    peraturan regulator lainnya.
                                </p>
                                <Row>
                                    <Col>
                                        <ul style={{ textAlign: "left" }}>
                                            <li>Resolusi 4K Ultra HD</li>
                                            <li>Accelerated Life Testing</li>
                                            <li>
                                                LED dengan rating 50,000 jam
                                            </li>
                                            <li>
                                                Fully heat-tempered, kaca anti
                                                silau
                                            </li>
                                            <li>
                                                Permukaan layar Silktouch™ bebas
                                                friksi
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col>
                                        <ul style={{ textAlign: "left" }}>
                                            <li>Sertifikasi ENERGY STAR®</li>
                                            <li>
                                                Material dan sumber bebas
                                                konflik
                                            </li>
                                            <li>
                                                Sertifikasi regulasi dan
                                                lingkungan
                                            </li>
                                            <li>Garansi SMART Assure*</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Tabs
                                    defaultActiveKey="profile"
                                    id="uncontrolled-tab-example"
                                >
                                    <Tab eventKey="home" title="MX265 / MX365 Series">
                                        <ProductTable
                                            tableName={"X265 / MX365"}
                                            tableData={Content["mx265/365"]}
                                        />
                                    </Tab>
                                    <Tab
                                        eventKey="profile"
                                        title="MX275 / MX375 Series"
                                    >
                                        <ProductTable
                                            tableName={"MX275 / MX375"}
                                            tableData={Content["mx275/375"]}
                                        />
                                    </Tab>
                                    <Tab
                                        eventKey="contact"
                                        title="MX286 / MX386 Series"
                                    >
                                        <ProductTable
                                            tableName={"MX286 / MX386"}
                                            tableData={Content["mx286/386"]}
                                        />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};
smartBoard.propTypes = {
    siteTitle: PropTypes.string,
};

smartBoard.defaultProps = {
    siteTitle: ``,
};
export default smartBoard;
